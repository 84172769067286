/* eslint-disable max-lines */
import
{
  Injectable,
  Injector,
  inject
} from "@angular/core";

import
{
  ActivatedRoute,
  CanMatchFn,
  Route, Router, UrlTree
} from "@angular/router";

import
{
  BehaviorSubject, Observable, catchError, filter, isObservable, map, mergeMap, of, tap, switchMap
} from "rxjs";

import
{
  APIService, unwrapResponse
} from "./api.service";
import
{
  ErrorHandlerCreator, ResponseErrorHandler
} from "./response-error-handler.service";
import { AuthService } from "../../auth/auth.service";
import { Permission } from "../models/permission";
import { MsalGuard } from "@azure/msal-angular";
import { isBoolean } from "shared-lib";
import { Store } from "@ngrx/store";
import { ClientOrganizationService } from "./client-organization.service";
import * as appActions from "../../store/app.actions";
import * as appSelectors from "../../store/app.selectors";

@Injectable({
  providedIn: "root"
})
export class AuthCanMatchService
{
  private userPermissions$ =
    new BehaviorSubject<Map<string, string> | null>(null);

  private handleError: ErrorHandlerCreator;

  private authService: AuthService;

  private readonly api: APIService;

  private readonly router: Router;

  constructor(
    private injector: Injector,
    private readonly responseErrorHandler: ResponseErrorHandler,
    private store: Store,
    private clientOrganizationService: ClientOrganizationService
  )
  {
    this.handleError = this.responseErrorHandler.createErrorHandler("AuthCanMatchService");
    this.authService = this.injector.get<AuthService>(AuthService);
    this.api = this.injector.get<APIService>(APIService);
    this.router = this.injector.get<Router>(Router);
  }

  canMatch(route: Route)
    : Observable<boolean | UrlTree>
    | boolean | UrlTree
  {
    // Check if signed in by microsoft
    if (this.authService.signedIn)
    {
      if (this.clientOrganizationService.clientOrganization == '')
      {
        this.store.dispatch(appActions.loadClientOrganization());
    }

      // Check if permissions from DB is allowed
      return this.getUserPermission().pipe(
        switchMap(permissions =>
        {
          const permissionObject: Map<string, string> | null = permissions;
          const moduleName: string = route && route.path ? route.path.toString() : "";

          return this.store.select(appSelectors.selectClientOrganizationIsReturn()).pipe(
            switchMap(isReturn =>
            {
              return this.store.select(appSelectors.selectClientOrganization()).pipe(
                filter(e=>isReturn==true),
                map(res =>
                {
                  if (res == null || typeof res === 'object' && Object.keys(res).length === 0)
                  {
                    void this.router.navigate(["unregistered"]);
                    return false;
                  } else
                  {
                    if (permissionObject && permissionObject.get(moduleName) != null )
                    {
                      return true;
                    } else
                    {
                      void this.router.navigate(["blocked"]);
                      return false;
                    }
                  }
                })
              );
            })
          );
        })
      );

    }

    void this.router.navigate(["blocked"]);

    return of(false);
  }

  getUserPermission(): Observable<Map<string, string> | null>
  {
    if (this.userPermissions$.getValue())
    {
      return of(this.userPermissions$.getValue());
    }
    else
    {
      return this.getPermissions().pipe(
        tap(res =>
        {
          this.userPermissions$.next(res);

          return res;
        })
      );
    }
  }

  getPermissions(): Observable<Map<string, string> | null>
  {
    return this.api.get<Permission[]>("Permission").pipe(
      unwrapResponse(),
      catchError(this.handleError("getPermissions", new Array<Permission>())),
      map(result =>
      {
        const permissionObject: Map<string, string> = new Map<string, string>();

        result.forEach((permission: Permission) =>
        {
          permissionObject.set(permission.PermissionModule,
            permission.PermissionLevel);
        });

        return permissionObject;
      })
    );
  }
}

export const canMatch: CanMatchFn = (route: Route) =>
{
  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);
  const msalGuard = inject(MsalGuard);
  const authCanMatchService = inject(AuthCanMatchService);

  return msalGuard
    .canActivate(activatedRoute.snapshot, router.routerState.snapshot)
    .pipe(
      mergeMap(result =>
      {
        const r = isBoolean(result) && result === true
          ? authCanMatchService.canMatch(route)
          : of(result);

        return isObservable(r) ? r : of(r);
      })
    );
};